'use strict';

var _ = require('lodash');

var utils = {
    /**
     * @desc Media breakpoints that are used throughout the Javascript
     */
    breakpoints: {
        xs: 1,
        sm: 576,
        tmd: 769,
        md: 768,
        tlg: 1024,
        lg: 1025,
        xl: 1440,
        xxl: 1680
    },

    /**
     * @function
     * @description Returns either an object with all of the available breakpoints or a specific viewport based on the given size
     * @param {string=} size The viewport to return
     * @param {Object=} breakpoints A custom breakpoints object
     * @returns {Object|string} - breakpoints or specific viewport
     */
    getViewports: function (size, breakpoints) {
        var bps = breakpoints || this.breakpoints;

        if (size) {
            var viewport = bps[size];

            if (viewport) {
                return viewport;
            }

            window.console.error('Unexpected viewport size given in util.getViewports');
            throw new Error('Unexpected viewport size given in util.getViewports');
        } else {
            return breakpoints;
        }
    },

    /**
     * @function
     * @description Returns the current viewport name (ex: 'medium') or 'max' if the current window is larger than any defined viewport width
     * @returns {string} - current viewport name
     */
    getCurrentViewport: function () {
        var w = window.innerWidth;
        var viewports = utils.getViewports();
        var viewport = 'max';
        // Traverse the object from small up to desktop, and return the first match
        _.each(viewports, function (value, name) {
            if (w <= value) {
                viewport = name;
            }
        });
        return viewport;
    },

    /**
     * @function
     * @description appends the parameter with the given name and value to the given url and returns the changed url
     * @param {string} url the url to which the parameter will be added
     * @param {string} name the name of the parameter
     * @param {string} value the value of the parameter
     * @returns {string} - URL with parameter
     */
    appendParamToURL: function (url, name, value) {
        // quit if the param already exists
        if (url.indexOf(name + '=') !== -1) {
            return url;
        }
        var separator = url.indexOf('?') !== -1 ? '&' : '?';
        return url + separator + name + '=' + encodeURIComponent(value);
    },

    /**
     * @function
     * @description remove the parameter and its value from the given url and returns the changed url
     * @param {string} url the url from which the parameter will be removed
     * @param {string} name the name of parameter that will be removed from url
     * @returns {string} - URL without parameter
     */
    removeParamFromURL: function (url, name) {
        if (url.indexOf('?') === -1 || url.indexOf(name + '=') === -1) {
            return url;
        }
        var hash;
        var params;
        var domain = url.split('?')[0];
        var paramUrl = url.split('?')[1];
        var newParams = [];
        // if there is a hash at the end, store the hash
        if (paramUrl.indexOf('#') > -1) {
            hash = paramUrl.split('#')[1] || '';
            paramUrl = paramUrl.split('#')[0];
        }
        params = paramUrl.split('&');
        for (var i = 0; i < params.length; i++) {
            // put back param to newParams array if it is not the one to be removed
            if (params[i].split('=')[0] !== name) {
                newParams.push(params[i]);
            }
        }
        return domain + '?' + newParams.join('&') + (hash ? '#' + hash : '');
    },

    /**
     * appends params to a url
     * @param {string} url - Original url
     * @param {Object} params - Parameters to append
     * @returns {string} result url with appended parameters
     */
    appendToUrl: function (url, params) {
        var newUrl = url;
        newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
            return key + '=' + encodeURIComponent(params[key]);
        }).join('&');

        return newUrl;
    },

    /**
     * @function
     * @description extract the query string from URL
     * @param {string} url the url to extra query string from
     * @returns {string|Object} - Query String from URL
     **/
    getQueryString: function (url) {
        var qs;
        if (!_.isString(url)) {
            return null;
        }
        var a = document.createElement('a');
        a.href = url;
        if (a.search) {
            qs = a.search.substr(1); // remove the leading ?
        }
        return qs;
    },

    /**
     * @function
     * @description Extracts all parameters from a given query string into an object
     * @param {string} qs The query string from which the parameters will be extracted
     * @returns {Object} - Object with params from the query string
     */
    getQueryStringParams: function (qs) {
        if (!qs || qs.length === 0) {
            return {};
        }
        var params = {};
        var unescapedQS = decodeURIComponent(qs);
        // Use the String::replace method to iterate over each
        // name-value pair in the string.
        unescapedQS.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
            function ($0, $1, $2, $3) {
                params[$1] = $3;
            }
        );
        return params;
    },

    /**
     * @function
     * @desc Determines if the device that is being used is mobile
     * @returns {boolean} - Wether or not the browser is currently mobile
     */
    isMobile: function () {
        var mobileAgentHash = ['mobile', 'tablet', 'phone', 'ipad', 'ipod', 'android', 'blackberry', 'windows ce', 'opera mini', 'palm'];
        var idx = 0;
        var isMobile = false;
        var userAgent = (navigator.userAgent).toLowerCase();

        while (mobileAgentHash[idx] && !isMobile) {
            isMobile = (userAgent.indexOf(mobileAgentHash[idx]) >= 0);
            idx++;
        }
        return isMobile;
    },

    /**
     * Set cookie value by cookie name, value and number of days as lifetime to browser
     * @param {string} name - name of the cookie
     * @param {boolean} value - value of the cookie
     * @param {integer} days - name of the cookie
     * cookieLifetime - If you set the number of days to
     * 0 the cookie is trashed when the user closes the browser
     * set the days to a negative number the cookie is trashed immediately
     * any number (ex - 7) - the cookie should be active for 7 days
     * @returns {string} set cookie name and value as per the parameters passed
     */
    setCookie: function (name, value, days) {
        var expires = '';
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + (value || '') + expires + '; path=/';
    },

    /**
     * Get cookie value by cookie name from browser
     * @param {string} cookieName - name of the cookie
     * @returns {string} cookie value of the found cookie name
     */
    getCookie: function (cookieName) {
        var name = cookieName + '=';
        var decodedCookie = decodeURIComponent(document.cookie);
        var cookieArray = decodedCookie.split(';');
        for (var i = 0; i < cookieArray.length; i++) {
            var cookieItem = cookieArray[i];
            while (cookieItem.charAt(0) === ' ') {
                cookieItem = cookieItem.substring(1);
            }
            if (cookieItem.indexOf(name) === 0) {
                return cookieItem.substring(name.length, cookieItem.length);
            }
        }
        return '';
    },

    /**
     * Get cookie value by cookie name from browser
     * @param {string} name - find the name name of the cookie to be trashed
     */
    clearCookie: function (name) {
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },

    /**
     * @function
     * @description Executes a callback function when the user has stopped resizing the screen.
     * @param {function} callback - Callback function for the resize event
     * @return  {function} - Callback function for the resize event
     */
    smartResize: function (callback) {
        var timeout;

        $(window).on('resize', function () {
            clearTimeout(timeout);
            timeout = setTimeout(callback, 100);
        }).resize();

        return callback;
    },

    /**
     * @function
     * @desc Generates a min-width matchMedia media query based on the given params
     * @param {string} size - Breakpoint to use for the media query
     * @param {Object} breakpoints - Override of the util breakpoints (optional)
     * @returns {boolean} - Wether or not the given media query matches
     */
    mediaBreakpointUp: function (size, breakpoints) {
        var breakpoint = this.getViewports(size, breakpoints);
        var mediaQuery = window.matchMedia('(min-width: ' + breakpoint + 'px)');
        return mediaQuery.matches;
    }
};

module.exports = utils;
