'use strict';

var promoHomeSlidesCount = $('.promo-wrapper .promo-item').length;
var reviewHomeSlidesCount = $('.review-wrapper .review-tile').length;
var tabsHomeSlidesCount = $('.nav-pills-wrapper .nav-pills-item').length;

module.exports = {
    hero: {
        initialSlide: 0,
        direction: 'horizontal',
        slidesPerView: 'auto',
        speed: 300,
        effect: 'fade',
        crossFade: true,
        loop: true,
        freeMode: true,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        }
    },

    tabs: {
        initialSlide: tabsHomeSlidesCount,
        spaceBetween: 10,
        slidesPerView: 'auto'
    },

    promoHome: {
        initialSlide: promoHomeSlidesCount,
        spaceBetween: 16,
        slidesPerView: 'auto',
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        }
    },

    reviewHome: {
        initialSlide: reviewHomeSlidesCount,
        spaceBetween: 16,
        slidesPerView: 'auto'
    },

    pdp: {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 8,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        }
    }
};
