'use strict';

var swiperConfigs = require('../configs/swiperConfigs');
const utils = require('../util/utils');
const Swiper = require('swiper/bundle').Swiper;

/**
 * @desc init global slider
 */
function initGlobalScrollerSlider() {
    var heroswiper = new Swiper('.homepage-swiper', swiperConfigs.hero); //eslint-disable-line
}

/**
 * @desc init promo slider
 */
function initPromoSlider() {
    var promoswiper = new Swiper('.promo-wrapper-inner', swiperConfigs.promoHome); //eslint-disable-line
}

/**
 * @desc init promo slider
 */
function initReviewSlider() {
    var reviewswiper = new Swiper('.review-wrapper-inner', swiperConfigs.reviewHome); //eslint-disable-line
}

function initPDPSlider() {
    var pdpswiper = new Swiper('.product-wrapper-inner', swiperConfigs.pdp); //eslint-disable-line
}
function initTabSlider() {
    var swiperWidth = 0;
    var bodyWidth = $('body').width();
    $('.nav-pills .swiper-wrapper .swiper-slide').each(function () {
        swiperWidth += $(this).width();
    });

    if (swiperWidth > bodyWidth) {
        $('.nav-pills .swiper-wrapper').removeClass('justify-content-center');
    } else {
        $('.nav-pills .swiper-wrapper').addClass('justify-content-center');
    }

    var tabSwiper = new Swiper('.dashboard-nav .nav-pills', swiperConfigs.tabs); //eslint-disable-line
}
$(document).ready(function () {
    initGlobalScrollerSlider();
    initTabSlider();
    initPromoSlider();
    initReviewSlider();
    initPDPSlider();
});
utils.smartResize(function () {
    initTabSlider();
    initPromoSlider();
    initReviewSlider();
    initPDPSlider();
});
